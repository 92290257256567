<template>
  <main
    class="single"
    :class="{'hide__controls' : controlsHidden && (transition !== 'to-work' || isMobile) }"
    :data-disable-hide="stopHiding ? 'true' : 'false'"
  >
    <Spacer x="l">
      <div
        class="grid single__info"
      >
        <Column
          :size="{default:4, m:3}"
          class="single__info--wrapper"
          @mouseenter.native="disableHide"
          @mouseleave.native="ableHide"
          @touchstart.native="disableHide"
          @touchleave.native="ableHide"
        >
          <!-- INIZIO GRANDE ACCORDION -->
          <Accordion>
            <AccordionItem>
              <template slot="accordion-trigger">
                <Grid
                  :extra-class="['single__line', 'single__title']"
                  @click.native="openAccordionBig"
                >
                  <Column
                    :size="{default: 4, m: 3}"
                    class="single__number"
                  >
                    <richtext
                      class="nowrap numericvariable"
                      :data="{ value: post.acf.number }"
                    />
                  </Column>
                  <Column
                    :size="{default: 4, m: 6}"
                  >
                    <Flex
                      align="marghe"
                    >
                      <Title
                        class="nowrap"
                        :data="{ value: post.title.rendered }"
                        :title="post.title.rendered "
                      />
                      <div
                        class="single__dot single__dot--right single__dot--white"
                        :class="{'single__dot--full' :accordionBigOpen}"
                      />
                    </Flex>
                  </Column>
                </Grid>
              </template>
              <template slot="accordion-content">
                <div
                  class="accordion-big"
                >
                  <div class=" single__line--double">
                    <h2
                      class="single__line nowrap double__first"
                    >
                      Director
                    </h2>
                    <Title
                      class="single__line span__2 nowrap double__second"
                      :data="{ value: post.acf.director }"
                      :title="post.acf.director"
                    />
                  </div>

                  <div class=" single__line--double">
                    <h2 class="single__line double__first">
                      Client
                    </h2>
                    <Title
                      class="single__line span__2 double__second"
                      :data="{ value: post.acf.client }"
                    />
                  </div>

                  <div class="single__line--double">
                    <h2 class="single__line double__first">
                      Category
                    </h2>
                    <Title
                      class="single__line span__2 double__second"
                      :data="{ value: post.gds_taxonomies.categories[0].cat_name }"
                    />
                  </div>

                  <div class="single__line--double single__line--double--last">
                    <h2 class="single__line double__first">
                      Year
                    </h2>
                    <Title
                      class="span__2 single__line numericvariable double__second"
                      :data="{ value: post.acf.year }"
                    />
                  </div>

                  <div
                    v-if="post.acf.video_description"
                    class="single__accordion"
                  >
                    <Accordion>
                      <AccordionItem>
                        <template slot="accordion-trigger">
                          <Flex
                            align="v-center"
                            class="single__line single__accordion--title"
                            @click.native="openAccordionDesc"
                          >
                            <div
                              class="single__dot noselect single__dot--white"
                              :class="{'single__dot--full' :accordionDescOpen}"
                            />
                            <h2>
                              Description
                            </h2>
                          </Flex>
                        </template>

                        <template slot="accordion-content">
                          <div
                            class="single__line single__line--accordion"
                          >
                            <richtext
                              :data="{ value: post.acf.video_description }"
                            />
                          </div>
                        </template>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  <div
                    v-if="post.acf.credits_list"
                    class="single__accordion"
                  >
                    <Accordion>
                      <AccordionItem>
                        <template slot="accordion-trigger">
                          <Flex
                            align="v-center"
                            class="single__line single__accordion--title"
                            @click.native="openAccordionCredits"
                          >
                            <div
                              class="single__dot noselect single__dot--white"
                              :class="{'single__dot--full' :accordionCreditsOpen}"
                            />
                            <h2>
                              Credits
                            </h2>
                          </Flex>
                        </template>
                        <template slot="accordion-content">
                          <div
                            class="single__line single__line--accordion"
                          >
                            <div
                              v-for="(credit, index) in post.acf.credits_list"
                              :key="index"
                              class="credits__block"
                            >
                              <Grid>
                                <Column
                                  :size="{default: 2, m: 3 }"
                                >
                                  <p
                                    class=""
                                    :title="credit.role"
                                  >
                                    {{ credit.role }}
                                  </p>
                                </Column>
                                <Column
                                  :size="{default: 2, m: 6 }"
                                  :title="credit.name"
                                >
                                  <p class="">
                                    {{ credit.name }}
                                  </p>
                                </Column>
                              </Grid>
                            </div>
                          </div>
                        </template>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  <div
                    v-if="post.acf.gallery"
                    class="single__accordion"
                  >
                    <Accordion>
                      <AccordionItem :opened-fn="accordionLazyload">
                        <template slot="accordion-trigger">
                          <Flex
                            align="v-center"
                            class="single__line single__accordion--title"
                            @click.native="openAccordionBackstage"
                          >
                            <div
                              class="single__dot noselect single__dot--white"
                              :class="{'single__dot--full' :accordionBackstageOpen}"
                            />
                            <h2>
                              Backstage
                            </h2>
                          </Flex>
                        </template>
                        <template slot="accordion-content">
                          <div
                            class="single__line single__line--backstage single__line--accordion"
                          >
                            <Spacer
                              v-for="(figure, index) in post.acf.gallery"
                              :key="index"
                              :bottom="index == post.acf.gallery.length - 1 ? false : 's'"
                            >
                              <Figure
                                :data="figure"
                              />
                            </Spacer>
                          </div>
                        </template>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </template>
            </AccordionItem>
          </Accordion>

          <!-- FINO GRANDE ACCORDION -->
        </Column>
      </div>
    </Spacer>

    <div
      v-if="transition !== 'to-work' || isMobile"
      class="video__wrapper main__video"
      @click="toggleVideo"
      @keyup.space="toggleVideo"
      @mousemove="checkMovement"
      @touchmove="checkMovement"
    >
      <Video
        v-if="post.acf.vimeo_id_full"
        ref="videoPlayer"
        :data="post.acf.vimeo_id_full"
        :fh="true"
      />
    </div>

    <div class="single__video--command single__video--play">
      <div
        v-if="transition !== 'to-work' || isMobile"
        class="play__button"
        @click.stop
      >
        {{ toggleName }}
      </div>
    </div>

    <Flex
      v-if="transition !== 'to-work' || isMobile"
      class="single__video--command single__video--top"
      @click.stop
      @mouseenter.native="disableHide"
      @mouseleave.native="ableHide"
      @touchstart.native="disableHide"
      @touchleave.native="ableHide"
    >
      <div
        class="single__video--audio"
        @click="toggleAudio"
      >
        Audio {{ audioStatus }}
      </div>

      <div
        class="single__video--close"
      >
        <router-link
          to="/works"
        />
      </div>
    </Flex>

    <!-- INIZIO RELATED -->
    <!-- @tommy: se eventualmente ancora non è reattivo una soluzione può essere tirarlo fuori -->

    <div
      v-if="isMobile"
      class="related"
      @touchstart="disableHide"
      @touchleave="ableHide"
    >
      <Accordion>
        <AccordionItem
          :pre-opened-fn="moveCommands"
        >
          <template slot="accordion-trigger">
            <div class="related__title numericvariable">
              Related
            </div>
          </template>
          <template slot="accordion-content">
            <div
              class="related__list"
            >
              <div
                v-for="item in workList"
                :key="item.id"
                class="related__item"
              >
                <router-link
                  :to="item.link"
                >
                  <div class="related__list--number numericvariable">
                    {{ item.acf.number }}
                  </div>

                  <Video
                    v-if="item.acf.vimeo_id_teaser"
                    ref="videoPrev"
                    :muted="true"
                    :data="item.acf.vimeo_id_teaser"
                    :preview="true"
                  />
                  <Figure
                    v-if="!item.acf.vimeo_id_teaser && item.acf.poster_teaser"
                    :data="item.acf.poster_teaser"
                    :ratio-from-props="false"
                    :lazy="false"
                  />
                </router-link>
              </div>
            </div>
          </template>
        </AccordionItem>
      </Accordion>
    </div>

    <!-- FINE RELATED -->

    <!-- INIZIO PREV -->

    <div class="prevnext">
      <router-link
        class="prev"
        :to="{
          name: 'SingleWork',
          params: {
            slug: prevWork.slug,
            direction: 'prev',
          },
        }"
      >
        <div
          class="prevnext__wrapper prev"
          :class="{'prevnext--opacity' : prevVideoStatus===false}"
          @mouseenter="showPrevVideo"
          @mouseleave="hidePrevVideo"
        >
          <div class="prevnext__title numericvariable">
            {{ prevWork.acf.number }}
          </div>
          <transition
            :css="false"
            @enter="enterPrevNext"
            @leave="leavePrevNext"
          >
            <div
              v-show="prevVideoStatus"
            >
              <Video
                v-if="prevWork.acf.vimeo_id_teaser"
                ref="videoPrev"
                :muted="true"
                :data="prevWork.acf.vimeo_id_teaser"
              />
              <Figure
                v-if="!prevWork.acf.vimeo_id_teaser && prevWork.acf.poster_teaser"
                :data="prevWork.acf.poster_teaser"
                :ratio-from-props="56.25"
                :lazy="false"
              />
            </div>
          </transition>
        </div>
      </router-link>
      <!-- FINE PREV -->
    </div>

    <!-- INIZIO NEXT -->
    <div class="prevnext">
      <router-link
        class="next"
        :to="{
          name: 'SingleWork',
          params: {
            slug: nextWork.slug,
            direction: 'next',
          },
        }"
      >
        <div
          class="prevnext__wrapper"
          :class="{'prevnext--opacity' : nextVideoStatus===false}"
          @mouseenter="showNextVideo"
          @mouseleave="hideNextVideo"
        >
          <div class="prevnext__title numericvariable">
            {{ nextWork.acf.number }}
          </div>
          <transition
            :css="false"
            @enter="enterPrevNext"
            @leave="leavePrevNext"
          >
            <div v-show="nextVideoStatus">
              <Video
                v-if="nextWork.acf.vimeo_id_teaser"
                ref="videoNext"
                :data="nextWork.acf.vimeo_id_teaser"
              />
              <Figure
                v-if="!nextWork.acf.vimeo_id_teaser && nextWork.acf.poster_teaser"
                :data="nextWork.acf.poster_teaser"
                :ratio-from-props="56.25"
                :lazy="false"
              />
            </div>
          </transition>
        </div>
      </router-link>
    </div>

    <!-- FINE NEXT -->
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import gsap from 'gsap';
// import Gallery from '@/components/media/gallery';
import Richtext from '@/components/typo/richtext';
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';
import Accordion from '@/components/ui/Accordion';
import AccordionItem from '@/components/ui/AccordionItem';
import Title from '@/components/typo/title';
import data from '@/mixins/data';
import lazyload from '@/mixins/lazyload';

export default {
  name: 'SingleWork',
  components: {
    Title,
    Richtext,
    Figure,
    Video,
    Accordion,
    AccordionItem,
  },
  mixins: [data, lazyload],
  data() {
    return {
      accordionBackstageOpen: false,
      accordionCreditsOpen: false,
      accordionDescOpen: false,
      accordionBigOpen: false,
      toggleName: 'play',
      audioStatus: 'on',
      nextVideoStatus: false,
      prevVideoStatus: false,
      relatedOpen: false,
      controlsHidden: false,
      stopHiding: false,
      timeout: false,
    };
  },
  computed: {
    ...mapGetters(['transition', 'isMobile', 'volume']),
    workList() {
      const workList = [];
      const keys = Object.keys(this.$store.state.wp.works);
      keys.forEach((key) => {
        if (key !== 'requests') {
          workList.push(this.$store.state.wp.works[key]);
        }
      });
      function compare(a, b) {
        if (a.acf.number < b.acf.number) {
          return -1;
        }
        if (a.acf.number > b.acf.number) {
          return 1;
        }
        return 0;
      }
      workList.sort(compare);
      return workList;
    },
    partialWorkList() {
      const thisIndex = this.workList.findIndex((x) => x.slug === this.$route.params.slug);
      let partialWorkList = [];

      partialWorkList = this.workList;

      partialWorkList.splice(thisIndex, 1);

      // partialWorkList.splice(index, 1);

      // partialWorkList = this.workList.filter((item) => item !== index);

      // console.log('ciao');
      // console.log(partialWorkList);

      // partialWorkList = this.workList;
      // console.log('ciaooo');
      return partialWorkList;
    },
    prevWork() {
      const index = this.workList.findIndex((x) => x.slug === this.$route.params.slug);
      let prevIndex;
      if (index === 0) {
        prevIndex = this.workList.length - 1;
      } else {
        prevIndex = index - 1;
      }
      return this.workList[prevIndex];
    },
    nextWork() {
      const index = this.workList.findIndex((x) => x.slug === this.$route.params.slug);
      let nextIndex;
      if (index === this.workList.length - 1) {
        nextIndex = 0;
      } else {
        nextIndex = index + 1;
      }
      return this.workList[nextIndex];
    },
  },
  mounted() {
    this.audioStatus = this.volume ? 'off' : 'on';
    if (this.transition !== 'to-work' || this.isMobile) {
      this.$refs.videoPlayer.plyr.play();
      this.toggleName = 'pause';
    }

    if (this.transition !== 'to-work' || this.isMobile) {
      setTimeout(this.checkMovement, 2000);
    } else if (document.querySelector('body.single')) {
      document.querySelector('body.single').dataset.disableHide = 'false';
    }
    // console.log(this.post)
    // document.querySelector('.plyr__poster').insertAdjacentHTML('afterend', '<div class="video__blurrer"></div>');
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    disableHide() {
      this.stopHiding = true;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'true';
      }
    },
    ableHide() {
      this.stopHiding = false;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'false';
      }
    },
    checkMovement() {
      this.$el.classList.remove('hide__controls');
      this.controlsHidden = false;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.stopHiding === false) {
          this.controlsHidden = true;
        } else {
          this.controlsHidden = false;
        }
        // console.log(this.controlsHidden);
        return this.controlsHidden;
      }, 2000);
    },

    openAccordionBackstage() {
      this.accordionBackstageOpen = !this.accordionBackstageOpen;
    },
    accordionLazyload() {
      this.$nextTick(() => {
        this.lazyObserve(this.$store.state.route.params.slug, this.$el);
      });
    },
    openAccordionCredits() {
      this.accordionCreditsOpen = !this.accordionCreditsOpen;
    },
    openAccordionDesc() {
      this.accordionDescOpen = !this.accordionDescOpen;
    },
    openAccordionBig() {
      this.accordionBigOpen = !this.accordionBigOpen;

      if (!this.accordionBigOpen) {
        this.accordionBackstageOpen = false;
        this.accordionCreditsOpen = false;
        this.accordionDescOpen = false;
      }
    },
    toggleVideo(e) {
      if (e.target.classList.contains('plyr__controls') || this.$el.querySelector('.plyr__controls').contains(e.target)) return;
      this.$refs.videoPlayer.plyr.togglePlay();
      const isPlaying = this.$refs.videoPlayer.plyr.playing;
      if (isPlaying === true) {
        this.toggleName = 'pause';
        this.$el.querySelector('.plyr__video-wrapper').classList.remove('blur');
      } else {
        this.toggleName = 'play';
        this.$el.querySelector('.plyr__video-wrapper').classList.add('blur');
      }
    },
    toggleAudio() {
      if (this.$refs.videoPlayer.plyr.muted === true) {
        this.$refs.videoPlayer.plyr.muted = false;
        this.$store.commit('SET_VOLUME', true);
        this.audioStatus = 'off';
      } else {
        this.$refs.videoPlayer.plyr.muted = true;
        this.$store.commit('SET_VOLUME', false);
        this.audioStatus = 'on';
      }
    },

    showNextVideo() {
      this.stopHiding = true;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'true';
      }

      this.nextVideoStatus = true;
      if (this.$refs.videoNext) {
        this.$refs.videoNext.plyr.muted = true;
        this.$refs.videoNext.plyr.loop = true;
        this.$refs.videoNext.plyr.play();
      }
    },
    hideNextVideo() {
      this.nextVideoStatus = false;
      this.stopHiding = false;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'false';
      }

      if (this.$refs.videoNext) {
        this.$refs.videoNext.plyr.stop();
      }
    },
    showPrevVideo() {
      this.stopHiding = true;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'true';
      }

      this.prevVideoStatus = true;
      if (this.$refs.videoPrev) {
        this.$refs.videoPrev.plyr.muted = true;
        this.$refs.videoPrev.plyr.loop = true;
        this.$refs.videoPrev.plyr.play();
      }
    },
    hidePrevVideo() {
      this.stopHiding = false;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'false';
      }
      this.prevVideoStatus = false;
      if (this.$refs.videoPrev) {
        this.$refs.videoPrev.plyr.stop();
      }
    },
    moveCommands() {
      this.relatedOpen = !this.relatedOpen;
      const relatedHeight = this.$el.querySelector('.related__list').clientHeight;
      const rightHeight = relatedHeight + 10;
      const playButton = this.$el.querySelector('.single__video--play');
      const controls = this.$el.querySelector('.plyr__controls');

      // console.log(relatedHeight);
      if (this.relatedOpen === true) {
        playButton.style.transform = `translateY(-${rightHeight}px)`;
        controls.style.transform = `translateY(-${rightHeight}px)`;
      } else {
        playButton.style.transform = 'translateY(0)';
        controls.style.transform = 'translateY(0)';
      }
      // this.accordionOpen = !this.accordionOpen;
    },

    enterPrevNext(el, done) {
      gsap.set(el, {
        height: 0,
        autoAlpha: 1,
        overflow: 'visible',
        clipPath: 'inset(100% 0% 0% 0%)',
        webkitClipPath: 'inset(100% 0% 0% 0%)',
      });

      gsap.killTweensOf(el);
      gsap.to(el, {
        height: 'auto',
        clipPath: 'inset(0% 0% 0% 0%)',
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        duration: 0.5,
        ease: 'power2.out',
        clearProps: 'all',
        onComplete: done,
      });
    },
    leavePrevNext(el, done) {
      gsap.killTweensOf(el);
      gsap.to(el, {
        height: 0,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: done,
      });
    },
  },
};

</script>
