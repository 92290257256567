<template>
  <Spacer>{{ data.id }}</Spacer>
</template>

<script>
export default {
  name: 'Placeholder',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
