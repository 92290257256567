<template>
  <div
    :class="['wysiwyg', data.size ? `typo--${data.size}` : null]"
    v-html="data.value"
  />
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => ({
        value: '',
      }),
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/src/03_typography';

.wysiwyg {
  ul, ol {
    display: block;
    margin: 1em 0;
    padding-left: $unit*2;
  }

  ul {
    list-style-type: disc;
  }
}
</style>
