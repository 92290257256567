<template>
  <!-- <router-link :to="data.link"> -->
  <div class="homepage noselect">
    <div
      class="tease "
      :class="{'tease__open' :accordionOpen}"
    >
      <div class=" tease__video">
        <Video
          ref="videoPlayer"
          :data="data.acf.vimeo_id_teaser"
          :muted="true"
          :loop="false"
          :fh="true"
        />
      </div>
      <router-link
        ref="content"
        :to="data.link"
        class="fullwidth"
      >
        <div
          class="grid tease__heading tease__accordion-trigger"
        >
          <Column
            class="tease__item-1"
            size="1"
          >
            <Title
              class=" nowrap numericvariable"
              :data="{ value: data.acf.number }"
            />
          </Column>
          <Column
            class="tease__item-2"
          >
            <Title
              class=" nowrap"
              :data="{ value: data.taxonomy ? data.name : data.title.rendered }"
              :title="data.title.rendered "
            />
          </Column>

          <Column
            class="tease__item-3"
          >
            <Title
              class=" nowrap"
              :data="{ value: data.acf.director }"
              :title="data.acf.director "
            />
          </Column>

          <Column
            class="tease__item-4"
          >
            <Title
              class=" nowrap tease__item-5"
              :data="{ value: data.acf.client }"
              :title="data.acf.client "
            />
          </Column>

          <Column
            class="tease__item-5"
            size="1"
          >
            <Title
              class=" nowrap"
              :data="{ value: data.gds_taxonomies.categories[0].cat_name }"
              :title="data.gds_taxonomies.categories[0].cat_name "
            />
          </Column>

          <Column
            class="tease__item-6"
            size="1"
          >
            <Title
              class=" nowrap numericvariable"
              :data="{ value: data.acf.year }"
              :title="data.acf.year "
            />
          </Column>
        </div>
      </router-link>
    </div>
  </div>

  <!-- </router-link> -->
</template>

<script>
import Title from '@/components/typo/title';
import Video from '@/components/media/video';

export default {
  name: 'Thumb',
  components: {
    Title,
    Video,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accordionOpen: false,
      accordionBackstageOpen: false,
      accordionCreditsOpen: false,
      accordionDescOpen: false,
      toggleName: 'play',
      isBlurred: false,

    };
  },
  mounted() {
    // this.$refs.videoPlayer.plyr.play();

    // this.$refs.videoPlayer.plyr.on('ended', function () {
    //   this.$refs.videoPlayer.plyr.play();
    // });
    // setTimeout(() => {
    //   this.$refs.videoPlayer.plyr.play();
    // }, 100);
  },
  methods: {
  },
};
</script>

<style>

</style>
