<template>
  <Wrapper>
    <Spacer>
      <Label
        v-if="data.label && data.label !== ''"
        :data="{
          value: data.label,
          tag: data.tag_label,
          size: data.size_label
        }"
      />
      <Title
        v-if="data.title !== ''"
        :data="{
          value: data.title,
          tag: data.tag_title,
          size: data.size_title
        }"
      />
      <RichText
        v-if="data.rich_text !== ''"
        :data="{ value: data.rich_text }"
      />
      <Cta
        v-if="data.cta && data.cta !== ''"
        :data="data.cta"
      />
    </Spacer>
  </Wrapper>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
