<template>
  <div
    class="cta"
  >
    <template v-if="data.fn">
      <button @click="data.fn">
        <Label
          :data="{
            value: data.title,
            size: data.size || 'l',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="data.external">
      <a
        :href="data.url"
        :target="data.target"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 'l',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        :to="data.url"
      >
        <Label
          :data="{
            value: data.title,
            size: data.size || 'l',
            tag: 'div'
          }"
        />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
import Label from '@/components/typo/label';

export default {
  name: 'Cta',
  components: {
    Label,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
    display: inline-block;
  }
</style>
