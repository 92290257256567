<template>
  <header>
    <div
      v-show="(transition === 'to-work' || $route.name !== 'SingleWork') && $route.name !== 'Index'"
      class="header__background"
      :class="{'background--white' : ($route.name === 'Page' || $route.name === 'About' && currentPath !== '/') && scroll >= 50 } "
    />
    <div
      class="header__logo"
    >
      <router-link
        v-show="(transition === 'to-work' && !isMobile || $route.name !== 'SingleWork')"
        to="/"
        class="logo"
      >
        <img
          v-if="currentPath === '/' || windowWidth < 1000 && $route.name === 'About' "
          src="~@/assets/img/logo-white.svg"
          alt=""
        >
        <img
          v-else
          src="~@/assets/img/logo.svg"
          alt=""
        >
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  props: {
    currentPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      scroll: 0,
    };
  },
  computed: {
    ...mapGetters(['transition', 'isMobile']),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.$aion.add(this.checkScroll, 'checkScroll', true);
  },
  beforeDestroy() {
    this.$aion.remove('checkScroll');
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    checkScroll() {
      this.scroll = window.pageYOffset;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
