<template>
  <nav
    v-show="$route.name !== 'SingleWork'"
    :class="{'white' : $route.name === 'Index', 'nav__blendmode' : $route.name !== 'Index' && $route.name !== '404', 'gray' : $route.name == 'About'}"
  >
    <Flex align="h-around">
      <div
        v-for="item in navigation"
        :key="item.id"
      >
        <a
          v-if="item.id === 192 && item.url === $route.path"
          href="#!"
          class="nav__link fx"
          @click.prevent="$parent.closeAbout"
        >
          {{ item.content }}
        </a>
        <router-link
          v-else
          :to="item.url"
          :class="[item.id === 52 && $route.name == 'About' ? 'opacity' : null, 'fx']"
        >
          {{ item.content }}
        </router-link>
      </div>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
