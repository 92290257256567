<template>
  <Spacer
    x="l"
    :class="{'homepage__spacer' : data.style.type === 'index' }"
  >
    <div
      v-if="items.length > 0"
      class="teases"
      :data-layout="data.style.type !== 'default' ? data.style.type : null"
      :class="[data.style.classes !== '' ? data.style.classes.split(' ') : null ]"
    >
      <div v-if="data.style.type === 'index'" />

      <div
        v-else
        class="order__flex"
      >
        <div
          v-if="isMobile"
        >
          <p class="opacity">
            Order
          </p>
        </div>
        <div class="grid order__grid">
          <Column
            class="tease__line tease__titles"
            size="1"
            :class="order === 'id' ? 'tease__line--selected' : null "
            @click.native="setOrderBy('id')"
          >
            <Flex align="v-center">
              <div
                class="single__dot single__dot__invisible"
                :class="order === 'id' ? 'single__dot__visible' : null "
              />
              <h2
                class="nowrap noselect"
                title="Catalog ID"
              >
                ID
              </h2>
            </Flex>
          </Column>
          <Column
            class="tease__line tease__titles"
            :size="{default:1, m:2}"
            :class="order === 'title' ? 'tease__line--selected' : null "
            @click.native="setOrderBy('title')"
          >
            <Flex align="v-center">
              <div
                class="single__dot single__dot__invisible"
                :class="order === 'title' ? 'single__dot__visible' : null "
              />
              <h2
                class="nowrap noselect"
                title="title"
              >
                Title
              </h2>
            </Flex>
          </Column>

          <Column
            class="tease__line tease__titles"
            :size="{default:1, m:2}"
            :class="order === 'director' ? 'tease__line--selected' : null "
            @click.native="setOrderBy('director')"
          >
            <Flex align="v-center">
              <div
                class="single__dot single__dot__invisible"
                :class="order === 'director' ? 'single__dot__visible' : null "
              />
              <h2
                class="nowrap noselect"
                title="director"
              >
                Director
              </h2>
            </Flex>
          </Column>

          <Column
            class="tease__line tease__titles"
            :size="{default:1, m:2}"
            :class="order === 'client' ? 'tease__line--selected' : null "
            @click.native="setOrderBy('client')"
          >
            <Flex align="v-center">
              <div
                class="single__dot single__dot__invisible"
                :class="order === 'client' ? 'single__dot__visible' : null "
              />
              <h2
                class="nowrap noselect"
                title="client"
              >
                Client
              </h2>
            </Flex>
          </Column>

          <Column
            class="tease__line tease__titles"
            size="1"
            :class="order === 'category' ? 'tease__line--selected' : null "
            @click.native="setOrderBy('category')"
          >
            <Flex align="v-center">
              <div
                class="single__dot single__dot__invisible"
                :class="order === 'category' ? 'single__dot__visible' : null "
              />
              <h2
                class="nowrap noselect"
                title="category"
              >
                Category
              </h2>
            </Flex>
          </Column>

          <Column
            class="tease__line tease__titles"
            size="1"
            :class="order === 'year' ? 'tease__line--selected' : null "
            @click.native="setOrderBy('year')"
          >
            <Flex align="v-center">
              <div
                class="single__dot single__dot__invisible"
                :class="order === 'year' ? 'single__dot__visible' : null "
              />
              <h2
                class="nowrap noselect"
                title="year"
              >
                Year
              </h2>
            </Flex>
          </Column>
        </div>
      </div>
      <div
        v-if="data.style.type === 'index'"
        ref="slider"
        class="slider"
      >
        <div
          v-for="item in items"
          :key="item.id"
          :class="['item', 'slider__item']"
        >
          <HomeThumb
            ref="homeThumb"
            :data="item"
          />
        </div>
      </div>
      <div
        v-else
      >
        <!-- :key="`${order}-${reverse}`" -->
        <div
          v-for="(item, index) in filteredItems"
          :key="item.id"
          :class="['item', data.style.type === 'index' ? 'slider__item' : null]"
        >
          <Thumb
            ref="archiveThumb"
            :data="item"
            :index="index"
            :set-current-thumb="setCurrentThumb"
            :current-thumb="currentThumb"
            :set-current-active="setCurrentActive"
            :current-active="currentActive"
          />
        </div>
      </div>
    </div>
    <div v-else>
      {{ placeholder }}
    </div>

    <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />
  </Spacer>
</template>

<script>
import gsap from 'gsap';
import VirtualScroll from 'virtual-scroll';
import orderBy from 'lodash.orderby';
import { mapGetters } from 'vuex';

import archive from '@/mixins/archive';
import Thumb from '@/components/thumb/index';
import HomeThumb from '@/components/thumb/homepage';

export default {
  name: 'Archive',
  components: {
    Thumb,
    HomeThumb,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      order: null,
      currentIndex: 0,
      scroller: null,
      currentY: 0,
      dir: 1,
      isAnimating: false,
      currentThumb: null,
      currentActive: null,
      reverse: false,
      isChangeRoute: false,
    };
  },
  computed: {
    ...mapGetters(['isMobile']),
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]: layout_size === 'auto' ? (12 / this.items.length).toString() : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          // m: (12 / (this.items?.length || this.data.categories?.length || this.data.posts?.length)).toString() || 3,
          m: 4,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
    filteredItems() {
      // let { items } = this;
      let items = JSON.parse(JSON.stringify(this.items));
      if (this.order) {
        let sort = 'asc';
        if (this.reverse) {
          sort = 'desc';
        }
        if (this.order === 'id') {
          items = orderBy(this.items, [(item) => item.acf.number], [sort]);
          items.reverse();
        } else if (this.order === 'title') {
          items = orderBy(this.items, [(item) => item.title.rendered, (item) => item.acf.number], [sort]);
        } else if (this.order === 'director') {
          items = orderBy(this.items, [(item) => item.acf.director, (item) => item.acf.number], [sort]);
        } else if (this.order === 'client') {
          items = orderBy(this.items, [(item) => item.acf.client, (item) => item.acf.number], [sort]);
        } else if (this.order === 'category') {
          items = orderBy(this.items, [(item) => item.gds_taxonomies.categories[0].cat_name, (item) => item.acf.number], [sort]);
        } else if (this.order === 'year') {
          items = orderBy(this.items, [(item) => parseInt(item.acf.year, 10), (item) => item.acf.number], [sort]);
        }
      }
      return items;
    },
  },
  watch: {
    $route(to, from) {
      if (from.name === 'SingleWork' && this.currentActive !== null) {
        if (this.$refs.archiveThumb[this.currentActive]) {
          this.$refs.archiveThumb[this.currentActive].$refs.videoPlayer.plyr.muted = true;
        }
        this.setCurrentActive(null);
      }
    },
    currentIndex(val) {
      this.isAnimating = true;
      this.$refs.homeThumb.forEach((thumb, index) => {
        if (index !== val) {
          gsap.set(thumb.$el.parentNode, {
            zIndex: 0,
            pointerEvents: 'none',
            autoAlpha: this.dir > 0 && val - 1 === index ? 1 : this.dir < 0 && val + 1 === index ? 1 : 0,
          });

          gsap.killTweensOf(thumb.$el.parentNode);
          gsap.to(thumb.$el.parentNode, {
            autoAlpha: 1,
            duration: 0.8,
            delay: 0.2,
            ease: 'power2.inOut',
            onComplete: () => {
              thumb.$refs.videoPlayer.plyr.stop();
            },
          });
        } else {
          gsap.set(thumb.$refs.content.$el, {
            autoAlpha: 0,
            y: 20,
          });

          gsap.set(thumb.$el.parentNode, {
            yPercent: 0,
            zIndex: 1,
            // autoAlpha: 1,
            pointerEvents: 'auto',
            autoAlpha: 0,
          });

          gsap.killTweensOf(thumb.$el.parentNode);
          gsap.to(thumb.$el.parentNode, {
            yPercent: 0,
            duration: 1.2,
            ease: 'power2.inOut',
            autoAlpha: 1,
            onComplete: () => {
              this.isAnimating = false;
            },
          });

          gsap.to(thumb.$refs.content.$el, {
            autoAlpha: 1,
            delay: 0.2,
            y: 0,
            duration: 1.2,
            ease: 'power2.inOut',
          });

          thumb.$refs.videoPlayer.plyr.play();
        }
      });

      // gsap.killTweensOf(this.$refs.slider);
      // gsap.to(this.$refs.slider, {
      //   y: `-${val * window.innerHeight}`,
      //   ease: 'power2.inOut',
      //   duration: 1.2,
      //   onComplete: () => {
      //     this.isAnimating = false;
      //   },
      // });
    },
    currentActive(val) {
      if (val && this.$route.name === 'SingleWork' && this.isMobile) {
        this.$refs.archiveThumb[val].$refs.videoPlayer.plyr.stop();
      }
    },
  },
  mounted() {
    if (this.data.style.type === 'index') {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('REMOVE_LOADER');
          this.$refs.homeThumb.forEach((thumb, index) => {
            if (thumb.$refs.videoPlayer.plyr) {
              thumb.$refs.videoPlayer.plyr.on('ended', () => {
                this.scrollEvent({ deltaY: -21 });
              });
            }

            if (index !== this.currentIndex) {
              gsap.set(thumb.$el.parentNode, {
                yPercent: 100,
                zIndex: 0,
                pointerEvents: 'none',
              });
            } else {
              gsap.set(thumb.$el.parentNode, {
                autoAlpha: 1,
                zIndex: 1,
                pointerEvents: 'auto',
              });
              gsap.set(thumb.$refs.content.$el, {
                autoAlpha: 0,
                y: 20,
              });

              gsap.to(thumb.$refs.content.$el, {
                autoAlpha: 1,
                delay: 0.2,
                y: 0,
                duration: 1.2,
                ease: 'power2.inOut',
              });

              if (thumb.$refs.videoPlayer.plyr) {
                thumb.$refs.videoPlayer.plyr.play();
              }
            }
          });
        }, 10);

        this.scroller = new VirtualScroll();
        this.scroller.on(this.scrollEvent);
      });
    } else {
      this.setOrderBy('id');
    }
  },
  beforeDestroy() {
    if (this.scroller) {
      this.scroller.off(this.scrollEvent);
      this.scroller = null;
    }
  },
  methods: {
    setOrderBy(by) {
      if (by === this.order) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
        this.order = by;
      }
    },
    setCurrentThumb(value) {
      this.currentThumb = value;
    },
    setCurrentActive(value) {
      this.currentActive = value;
    },
    scrollEvent(event) {
      if (this.isChangeRoute) return;
      if (!this.isAnimating && this.$route.name === 'Index' && Math.abs(event.deltaY) > 20) {
        this.dir = event.deltaY < 0 ? 1 : -1;
        if (this.currentIndex + this.dir < this.items.length && this.currentIndex + this.dir >= 0) {
          this.currentIndex += this.dir;
        } else if (this.currentIndex + this.dir === this.items.length) {
          this.isAnimating = true;
          this.isChangeRoute = true;
          this.$router.push('/works');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite {
  visibility: hidden;
  overflow: hidden;
}

.homepage__spacer {
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
}

.slider {
  position: relative;
  /* display: flex;
  flex-direction: column; */

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
  }
}
</style>
