<template>
  <!-- <router-link :to="data.link"> -->
  <div :class="{'hide__controls' : controlsHidden }">
    <div
      class="tease"
      :class="{'tease__open' : accordionOpen, 'tease--active': currentActive === index}"
      @mouseenter="teaseEnter"
      @mouseleave="teaseLeave"
    >
      <Accordion>
        <AccordionItem
          :pre-opened-fn="preOpenAccordion"
          :opened-fn="openAccordion"
        >
          <template
            slot="accordion-trigger"
          >
            <div
              class="grid tease__title tease__accordion-trigger noselect"
            >
              <Column
                class="tease__line tease__item-1"
                size="1"
              >
                <Title
                  class=" nowrap numericvariable"
                  :data="{ value: data.acf.number }"
                />
              </Column>
              <Column
                class="tease__line tease__item-2"
                :size="{default:1, m:2}"
              >
                <Title
                  class=" nowrap"
                  :data="{ value: data.taxonomy ? data.name : data.title.rendered }"
                  :title="data.title.rendered "
                />
              </Column>

              <Column
                class="tease__line tease__item-3"
                :size="{default:1, m:2}"
              >
                <Title
                  class=" nowrap"
                  :data="{ value: data.acf.director }"
                  :title="data.acf.director "
                />
              </Column>

              <Column
                class="tease__line tease__item-4"
                :size="{default:1, m:2}"
              >
                <Title
                  class=" nowrap"
                  :data="{ value: data.acf.client }"
                />
              </Column>

              <Column
                class="tease__line tease__item-5"
                size="1"
              >
                <Title
                  class=" nowrap"
                  :data="{ value: data.gds_taxonomies.categories[0].cat_name }"
                  :title="data.gds_taxonomies.categories[0].cat_name "
                />
              </Column>

              <Column
                class="tease__line tease__item-6"
                size="1"
              >
                <Title
                  class="nowrap numericvariable"
                  :data="{ value: data.acf.year }"
                  :title="data.acf.year "
                />
              </Column>
            </div>
          </template>

          <template slot="accordion-content">
            <div
              class="tease__separator grid"
            >
              <div class="tease__separator--line span__3" />
            </div>
            <div
              class=" tease__accordion-content accordion-content grid"
            >
              <div class="tease__info span__3">
                <div class="tease__scroller">
                  <div
                    v-if="data.acf.video_description"
                    class="tease__extra"
                  >
                    <Accordion>
                      <AccordionItem>
                        <template slot="accordion-trigger">
                          <Flex
                            align="v-center"
                            class="single__line noselect tease__extra-trigger dot__line"
                            @click.native="openAccordionDesc"
                          >
                            <div
                              class="single__dot"
                              :class="{'single__dot--black' :accordionDescOpen}"
                            />
                            <h2>
                              Description
                            </h2>
                          </Flex>
                        </template>
                        <template slot="accordion-content">
                          <div
                            class="single__line  tease__extra-content"
                          >
                            <Richtext
                              class=""
                              :data="{ value: data.acf.video_description }"
                            />
                          </div>
                        </template>
                      </AccordionItem>
                    </Accordion>
                  </div>
                  <div
                    v-if="data.acf.credits_list"
                    class="tease__extra"
                  >
                    <Accordion>
                      <AccordionItem>
                        <template slot="accordion-trigger">
                          <Flex
                            align="v-center"
                            class="single__line noselect dot__line"
                            @click.native="openAccordionCredits"
                          >
                            <div
                              class="single__dot"
                              :class="{'single__dot--black' :accordionCreditsOpen}"
                            />
                            <h2 class="">
                              Credits
                            </h2>
                          </Flex>
                        </template>
                        <template slot="accordion-content">
                          <div
                            class="single__line single__line--credits tease__extra-content"
                          >
                            <div
                              v-for="(credit, creditIndex) in data.acf.credits_list"
                              :key="creditIndex"
                              class="credits__block"
                            >
                              <Grid>
                                <Column
                                  :size="{default: 2, m: 3 }"
                                  class=" credits__role"
                                >
                                  <p
                                    class=""
                                    :title="credit.role"
                                  >
                                    {{ credit.role }}
                                  </p>
                                </Column>
                                <Column
                                  :size="{default: 2, m: 6 }"
                                  class=""
                                  :title="credit.name"
                                >
                                  {{ credit.name }}
                                </Column>
                              </Grid>
                            </div>
                          </div>
                        </template>
                      </AccordionItem>
                    </Accordion>
                  </div>

                  <div
                    v-if="data.acf.gallery"
                    class="tease__extra"
                  >
                    <Accordion>
                      <AccordionItem :opened-fn="accordionLazyload">
                        <template slot="accordion-trigger">
                          <Flex
                            align="v-center"
                            class="single__line noselect dot__line"
                            @click.native="openAccordionBackstage"
                          >
                            <div
                              class="single__dot"
                              :class="{'single__dot--black' :accordionBackstageOpen}"
                            />
                            <h2>
                              Backstage
                            </h2>
                          </Flex>
                        </template>
                        <template slot="accordion-content">
                          <div
                            class="single__line single__line--backstage single__line--accordion"
                          >
                            <Spacer
                              v-for="(figure, figureIndex) in data.acf.gallery"
                              :key="figureIndex"
                              :bottom="figureIndex == data.acf.gallery.length - 1 ? false : 's'"
                            >
                              <Figure
                                :data="figure"
                              />
                            </Spacer>
                          </div>
                        </template>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div
                class="span__6 tease__video"
                @click="toggleVideo"
                @keyup.space="toggleVideo"
              >
                <div
                  :class="['video__wrapper', currentActive === index ? 'video--active' : false]"
                  @mousemove="checkMovement"
                >
                  <Video
                    ref="videoPlayer"
                    :data="data.acf.vimeo_id_full"
                    :muted="true"
                  />

                  <div
                    v-if="$route.name === 'SingleWork' && currentActive === index"
                    class="single__video--command single__video--play"
                  >
                    <div
                      class="play__button"
                      @click.stop
                      @mouseenter="disableHide"
                      @mouseleave="ableHide"
                    >
                      {{ toggleName }}
                    </div>
                  </div>

                  <Flex
                    v-if="$route.name === 'SingleWork' && currentActive === index"
                    class="single__video--command single__video--top"
                  >
                    <div
                      class="single__video--audio"
                      @click.stop="toggleAudio"
                      @mouseenter="disableHide"
                      @mouseleave="ableHide"
                    >
                      Audio {{ audioStatus }}
                    </div>

                    <div
                      class="single__video--close"
                      @click.stop
                      @mouseenter="disableHide"
                      @mouseleave="ableHide"
                    >
                      <router-link
                        :to="{
                          name: 'Page',
                          params: {
                            slug: 'works'
                          }
                        }"
                      />
                    </div>
                  </Flex>

                  <div class="video__mask" />
                </div>
                <transition name="fade">
                  <div
                    v-if="isBlurred == false"
                    class="tease__video--blurrer"
                  />
                </transition>

                <div
                  class="tease__video--play noselect"
                >
                  {{ toggleName }}
                </div>
                <div class="tease__video--buttons noselect">
                  <div
                    class="tease__video--audio"
                    @click="toggleAudio"
                  >
                    Audio {{ audioStatus }}
                  </div>
                  <div
                    class="tease__video--full"
                    @click.stop
                  >
                    <router-link
                      :to="data.link"
                      @click.native="setCurrentActive(index)"
                    >
                      Full
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Accordionitem>
      </Accordion>
      <div
        class="tease__spacer"
        @click="redirectAccordion"
      />
    </div>
  </div>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

import lazyload from '@/mixins/lazyload';
import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext.vue';
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';
import Accordion from '@/components/ui/Accordion';
import AccordionItem from '@/components/ui/AccordionItem';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Thumb',
  components: {
    Title,
    Richtext,
    Figure,
    Video,
    Accordion,
    AccordionItem,
  },
  mixins: [lazyload],
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    currentThumb: {
      type: Number,
      default: () => {},
    },
    setCurrentThumb: {
      type: Function,
      default: () => {},
    },
    currentActive: {
      type: Number,
      default: () => {},
    },
    setCurrentActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      accordionOpen: false,
      accordionBackstageOpen: false,
      accordionCreditsOpen: false,
      accordionDescOpen: false,
      toggleName: 'play',
      isBlurred: false,
      windowWidth: window.innerWidth,
      active: false,
      audioStatus: 'on',
      controlsHidden: false,
      stopHiding: false,
      checkHide: false,
      timeout: false,
    };
  },
  watch: {
    currentThumb(val) {
      if (val !== this.index && this.accordionOpen) {
        this.$refs.videoPlayer.plyr.pause();
        this.toggleName = 'play';
        this.isBlurred = false;
      }
    },
    currentActive(val) {
      if (val === this.index) {
        setTimeout(this.checkMovement, 2000);

        gsap.set(this.$el.querySelector('.accordion__content'), {
          clearProps: 'all',
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    disableHide() {
      this.stopHiding = true;
      this.checkHide = true;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'true';
      }
      document.querySelectorAll('.single').forEach((item) => {
        item.classList.remove('hide__controls');
        item.dataset.disableHide = 'true';
      });
      this.controlsHidden = false;
    },
    ableHide() {
      this.checkHide = false;
      if (document.querySelector('body.single')) {
        document.querySelector('body.single').dataset.disableHide = 'false';
      }

      document.querySelectorAll('.single').forEach((item) => {
        item.classList.add('hide__controls');
        item.dataset.disableHide = 'false';
      });
    },
    checkMovement() {
      if (this.$route.name === 'SingleWork' && !this.mobile && !this.checkHide) {
        this.stopHiding = document.querySelector('main.single').dataset.disableHide === 'true';
        this.controlsHidden = false;

        document.querySelectorAll('.single').forEach((item) => item.classList.remove('hide__controls'));

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (this.stopHiding === false) {
            this.controlsHidden = true;
            document.querySelectorAll('.single').forEach((item) => item.classList.add('hide__controls'));
          } else {
            this.controlsHidden = false;
            document.querySelectorAll('.single').forEach((item) => item.classList.remove('hide__controls'));
          }
        }, 2000);
      }
    },
    redirectAccordion() {
      const trigger = this.$el.querySelector('.accordion__trigger');
      trigger.click();
    },
    teaseEnter() {
      const allTeases = document.querySelectorAll('.tease__title');
      allTeases.forEach((item) => {
        if (window.innerWidth > 999) {
          item.style.opacity = '0.25';
        }
      });
      this.$el.querySelector('.tease__title').style.opacity = '1';
    },
    teaseLeave() {
      const allTeases = document.querySelectorAll('.tease__title');
      allTeases.forEach((item) => {
        if (window.innerWidth > 999) {
          item.style.opacity = '1';
        }
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.accordionOpen === true && this.windowWidth > 999) {
        const teaseVideo = this.$el.querySelector('.video-player').clientHeight;
        const teaseToScroll = this.$el.querySelector('.tease__info');
        teaseToScroll.style.height = `${teaseVideo}px`;
      } else if (this.windowWidth < 999) {
        const teaseToScroll = this.$el.querySelector('.tease__info');
        if (teaseToScroll) {
          teaseToScroll.style.height = 'fit-content';
        }
      }
    },
    preOpenAccordion() {
      this.accordionOpen = !this.accordionOpen;

      if (!this.accordionOpen) {
        this.accordionBackstageOpen = false;
        this.accordionCreditsOpen = false;
        this.accordionDescOpen = false;
      }
    },
    openAccordion() {
      gsap.to(window, {
        scrollTo: {
          y: this.$el,
          offsetY: 50,
        },
        duration: 0.6,
        ease: 'power2.out',
        onComplete: () => {
          if (this.accordionOpen) {
            this.setCurrentThumb(this.index);
          } else {
            this.toggleVideo();
          }
          this.$nextTick(() => {
            if (this.accordionOpen === true && this.windowWidth > 999) {
              const teaseVideo = this.$el.querySelector('.video-player').clientHeight;
              const teaseToScroll = this.$el.querySelector('.tease__info');
              teaseToScroll.style.height = `${teaseVideo}px`;
            }
          });

          setTimeout(() => {
            if (this.accordionOpen === true) {
              this.$nextTick(() => {
                if (this.$refs.videoPlayer) {
                  this.$refs.videoPlayer.plyr.play();
                  const isPlaying = this.$refs.videoPlayer.plyr.playing;
                  if (isPlaying === true) {
                    this.toggleName = 'pause';
                    this.isBlurred = true;
                  } else {
                    this.toggleName = 'play';
                    this.isBlurred = false;
                  }
                }
              });
            }
          }, 10);
        },
      });
    },
    openAccordionBackstage() {
      this.accordionBackstageOpen = !this.accordionBackstageOpen;
    },
    accordionLazyload() {
      this.$nextTick(() => {
        this.lazyObserve(this.$store.state.route.params.slug, this.$el);
      });
    },
    openAccordionCredits() {
      this.accordionCreditsOpen = !this.accordionCreditsOpen;
    },
    openAccordionDesc() {
      this.accordionDescOpen = !this.accordionDescOpen;
    },
    toggleVideo() {
      this.$nextTick(() => {
        if (this.$route.name === 'SingleWork' && this.currentActive === this.index) {
          if (this.$el.querySelector('.plyr__video-wrapper') && this.$refs.videoPlayer) {
            this.$refs.videoPlayer.plyr.togglePlay();
            const isPlaying = this.$refs.videoPlayer.plyr.playing;
            if (isPlaying === true) {
              this.toggleName = 'pause';
              this.$el.querySelector('.plyr__video-wrapper').classList.remove('blur');
            } else {
              this.toggleName = 'play';
              this.$el.querySelector('.plyr__video-wrapper').classList.add('blur');
            }
          }
        } else if (this.$refs.videoPlayer) {
          this.$refs.videoPlayer.plyr.togglePlay();
          const isPlaying = this.$refs.videoPlayer.plyr.playing;
          if (isPlaying === true) {
            this.toggleName = 'pause';
            this.isBlurred = true;
            this.setCurrentThumb(this.index);
          } else {
            this.toggleName = 'play';
            this.isBlurred = false;
          }
        }
      });
    },
    toggleAudio(e) {
      if (this.$refs.videoPlayer.plyr.muted === true) {
        this.$store.commit('SET_VOLUME', true);
        this.$refs.videoPlayer.plyr.muted = false;
        this.audioStatus = 'off';
      } else {
        this.$refs.videoPlayer.plyr.muted = true;
        this.$store.commit('SET_VOLUME', false);
        this.audioStatus = 'on';
      }
      e.stopPropagation();
    },
  },
};
</script>

<style>

</style>
