<template>
  <figure
    :class="['picture', vh ? 'full-height' : false, ratioFromProps === false ? 'no-ratio' : false]"
    :data-lazy="lazy ? 'false' : null"
  >
    <picture
      v-if="imageType !== 'svg'"
      :style="ratioFromProps === false ? {} : { paddingBottom: !vh ? `${ratioFromProps || ratio}%` : false }"
    >
      <source
        v-for="(image, key) in sizes"
        :key="key"
        :srcset="image.url"
        :media="image.size"
        :data-media="key"
      >
      <img
        ref="image"
        class="image"
        :data-src="lazy ? defaultImage.sizes ? defaultImage.sizes.l : defaultImage.url : null"
        :src="!lazy ? defaultImage.sizes ? defaultImage.sizes.l : defaultImage.url : null"
        :alt="defaultImage.alt"
      >
      <div
        v-if="lazy"
        class="placeholder"
      />
    </picture>
    <div
      v-else-if="imageType === 'inline-svg'"
      class="svg"
      v-html="data.svg"
    />
    <div v-else-if="imageType === 'svg'">
      <img
        class="svg"
        :src="defaultImage.url"
        :alt="defaultImage.alt"
      >
    </div>
    <figcaption
      v-if="caption !== ''"
      v-html="caption"
    />
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    vh: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    ratioFromProps: {
      type: [Number, Boolean],
      default: () => {},
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      } if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
    ratio() {
      if (!this.data && !this.defaultImage) return 0;
      return (this.defaultImage.height / this.defaultImage.width) * 100;
    },
    sizes() {
      if (!this.data && !this.defaultImage) return [];
      if (!this.defaultImage.sizes) return [];

      let mobileSizes = null;
      const { sizes } = this.defaultImage;
      if (this.data.mobile) {
        mobileSizes = this.data.mobile.sizes;
      }

      const ratio = window.devicePixelRatio;

      return {
        xxs: {
          url: mobileSizes?.xss || sizes.xxs,
          size: `(max-width: ${(sizes['xxs-width']) / ratio}px)`,
        },
        xs: {
          url: mobileSizes?.xs || sizes.xs,
          size: `(max-width: ${(sizes['xs-width']) / ratio}px)`,
        },
        s: {
          url: mobileSizes?.s || sizes.s,
          size: `(max-width: ${(sizes['s-width']) / ratio}px)`,
        },
        m: {
          url: sizes.m,
          size: `(max-width: ${(sizes['m-width']) / ratio}px)`,
        },
        l: {
          url: sizes.l,
          size: `(max-width: ${(sizes['l-width']) / ratio}px)`,
        },
        xl: {
          url: sizes.xl,
          size: `(max-width: ${(sizes['xl-width']) / ratio}px)`,
        },
        xxl: {
          url: sizes.xxl,
          size: `(min-width: ${(sizes['xxl-width']) / ratio}px)`,
        },
      };
    },
    caption() {
      if (this.data.caption) {
        return this.data.caption;
      }
      if (this.defaultImage.caption) {
        return this.defaultImage.caption;
      }
      return '';
    },
  },
  mounted() {
    if (!this.lazy) {
      this.load();
    }
  },
  methods: {
    async load() {
      const img = this.$refs.image;
      if (img && img.decode) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  overflow: hidden;

  picture {
    position: relative;
    overflow: hidden;
    display: block;
    height: 0;
  }

  &.full-height {
    picture {
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &.no-ratio {
    picture {
      height: 100%;
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s $ease-custom;

    background-color: whitesmoke;
    will-change: opacity;
  }

  figcaption {
    text-align: left;
  }

  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
  }
}
</style>
