import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      lazySlug: null,
    };
  },
  watch: {
    post(val) {
      if (val) {
        this.lazyObserve(val.slug || this.$store.state.route.meta.slug || this.$store.state.route.params.slug, this.$el);
      }
    },
  },
  beforeUnmount() {
    if (this.lazySlug && medusa.ref && medusa.ref.idList.includes(this.lazySlug)) {
      medusa.ref.removeTarget(this.lazySlug);
    }
  },
  methods: {
    lazyObserve(id, el) {
      this.lazySlug = `lazyload-${id}`;
      if (medusa.ref.idList.includes(this.lazySlug)) {
        medusa.ref.removeTarget(this.lazySlug);
      }

      this.$nextTick(() => {
        const lazyLoadImgs = Array.from(el.querySelectorAll('figure.picture[data-lazy="false"] img.image'));

        medusa.ref.addTarget({
          id: this.lazySlug,
          container: this.$el,
          threshold: 0.0,
          nodes: lazyLoadImgs,
          mode: 'once',
          callback: this.decode,
          autoremove: false,
        });
      });
    },
    decode(entry) {
      const { target, isIntersecting } = entry;
      if (isIntersecting || window.innerHeight > this.$el.offsetHeight) {
        target.src = target.dataset.src;
        target.decode()
          .then(() => {
            target.closest('figure').dataset.lazy = true;
          })
          .catch(() => {
            target.closest('figure').dataset.lazy = true;
          });
      }
    },
  },
};
