<template>
  <div
    id="app"
  >
    <AppHeader :current-path="filteredPath" />
    <AppMenu />
    <transition
      :css="false"
      appear
      :mode="transition !== 'default' ? 'in-out' : 'out-in'"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="filteredPath" />
    </transition>

    <transition
      :css="false"
      appear
      :mode="transition === 'work-to-work' ? 'in-out' : 'out-in'"
      @enter="enterOverlay"
      @leave="leaveOverlay"
    >
      <router-view
        :key="$route.path"
        name="overlay"
      />
    </transition>
    <AppFooter />

    <div
      v-if="$route.name === 'About'"
      class="about__background"
      @click="closeAbout"
    />

    <div
      ref="disable"
      class="disable"
    />
  </div>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import { isTouchDevice, is } from '@/assets/js/utils';
import { mapGetters } from 'vuex';
import gsap from 'gsap';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import AppMenu from '@/components/ui/menu';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
  },
  data() {
    return {
      lastScroll: 0,
    };
  },
  computed: {
    ...mapGetters(['transition', 'loader', 'isMobile']),
    filteredPath() {
      let prev = this.$store.state.route.from && this.$store.state.route.from.name ? this.$store.state.route.from.path : null;
      if (prev && prev.length > 1 && prev.endsWith('/')) prev = prev.slice(0, -1);

      const prevPath = prev || (this.$route.name === 'About' ? '/' : '/works');
      let { path } = this.$route;
      if (path.length > 1 && path.endsWith('/')) path = path.slice(0, -1);
      return this.$route.name !== 'About' && this.$route.name !== 'SingleWork' ? path : prevPath;
    },
  },
  watch: {
    loader(val) {
      if (!val) {
        const el = document.querySelector('#loader');
        gsap.set(el, {
          transformOrigin: '0% 0%',
        });

        gsap.to(el, {
          transformOrigin: '0% 0%',
          scaleY: 0,
          ease: 'power2.inOut',
          background: '#111111',
          duration: 1,
          onComplete: () => {
            el.remove();
          },
        });
      }
    },
    $route() {
      if (this.loader && this.$route.name !== 'Index' && this.$route.name !== 'About') {
        this.$store.commit('REMOVE_LOADER');
      }
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    gsap.config({
      overwrite: 'auto',
      force3D: true,
      lazy: false,
    });
    // Look at the state ;)
    // console.log(this.$store.state);

    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener('resize', debounce(() => {
      this.$bus.$emit('windowResized');
    }));

    this.$bus.$on('windowResized', this.resize);
    this.resize();

    if (this.$route.name === '404') this.$store.commit('REMOVE_LOADER');
  },
  methods: {
    resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      const breakpoints = window.getComputedStyle(document.documentElement).getPropertyValue('--breakpoints').replace(/[ '"]+/g, '');
      this.$store.commit('SET_BREAKPOINTS', breakpoints);
      this.$store.commit('SET_TOUCH', isTouchDevice());
      this.$store.commit('SET_MOBILE', is('m'));

      if (this.transition === 'to-work' && !this.isMobile) {
        const video = document.querySelector('.video--active');
        const {
          top, left,
        } = video.getBoundingClientRect();

        const y = Math.floor(top * -1);
        const x = Math.floor(left * -1);

        gsap.set(video, {
          x: `+=${x}`,
          y: `+=${y}`,
        });
      }
    },
    enter(el, done) {
      if (this.transition === 'to-catalog') {
        this.$store.commit('SET_TRANSITION_STATUS', true);
        disableBodyScroll(this.$refs.disable);
        done();

        gsap.set(el, {
          y: window.innerHeight,
        });

        this.$nextTick(() => {
          gsap.to(el, {
            y: 0,
            ease: 'power2.inOut',
            duration: 1.2,
          });
        });
      } else {
        done();
      }
    },
    leave(el, done) {
      if (this.transition === 'index-to-work') {
        gsap.to(el, {
          autoAlpha: 0,
          duration: 1,
          onComplete: () => {
            this.$store.commit('SET_TRANSITION_STATUS', false);
            done();
          },
        });
      } else if (this.transition === 'to-catalog') {
        gsap.set(el, {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
        });

        gsap.to(el, {
          y: window.innerHeight * -1,
          ease: 'power2.inOut',
          delay: 0.2,
          duration: 1.2,
          onComplete: () => {
            this.$store.commit('SET_TRANSITION_STATUS', false);
            clearAllBodyScrollLocks();
            done();
          },
        });
      } else {
        done();
        this.$store.commit('SET_TRANSITION_STATUS', false);
      }
    },
    enterOverlay(el, done) {
      if (this.transition === 'to-about') {
        this.$store.commit('SET_TRANSITION_STATUS', true);

        // const { width, height } = el.children[0].getBoundingClientRect();

        // const scaleX = 40 / width;
        // const scaleY = 33 / height;

        gsap.set(el.children[0], {
          // scaleX,
          // scaleY,
          opacity: 1,
          clipPath: 'circle(0% at 100% 0%)',
          webkitClipPath: 'circle(0% at 100% 0%)',
          transformOrigin: '100% 0%',
        });

        // gsap.set(el.children[0].querySelector('.about__mask'), {
        //   clipPath: 'circle(200% at 0% 100%)',
        //   webkitClipPath: 'circle(200% at 0% 100%)',
        // });

        const tl = gsap.timeline();

        tl

          .to(el.children[0], {
            // scaleX: 1,
            // scaleY: 1,
            clipPath: 'circle(150% at 100% 0%)',
            webkitClipPath: 'circle(150% at 0% 0%)',
            duration: 1.4,
            ease: 'power2.inOut',
          });
        // .to(el.children[0].querySelector('.about__mask'), {
        //   clipPath: 'circle(0% at 0% 100%)',
        //   webkitClipPath: 'circle(0% at 0% 100%)',
        //   duration: 0.9,
        //   ease: 'power2.inOut',
        // }, 0);

        tl.then(() => {
          this.$store.commit('SET_TRANSITION_STATUS', false);
          done();
        });

        // gsap.to(el, {
        //   xPercent: 0,
        //   duration: 1,
        //   clearProps: 'all',
        //   onComplete: done,
        // });
      } else {
        this.lastScroll = window.pageYOffset;

        gsap.set('main.page', {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          y: this.lastScroll * -1,
        });
      }

      if (this.transition === 'index-to-work') {
        this.$store.commit('SET_TRANSITION_STATUS', true);
        gsap.set(el, {
          autoAlpha: 0,
        });
        gsap.to(el, {
          autoAlpha: 1,
          duration: 1,
          onComplete: () => {
            this.$store.commit('SET_TRANSITION_STATUS', false);
            done();
          },
        });
      }

      if (this.transition === 'to-work') {
        if (this.isMobile) {
          this.$store.commit('SET_TRANSITION_STATUS', true);
          gsap.set(el, {
            autoAlpha: 0,
          });

          gsap.to(el, {
            autoAlpha: 1,
            duration: 1.2,
            ease: 'power2.inOut',
            onComplete: () => {
              this.$store.commit('SET_TRANSITION_STATUS', false);
              done();
            },
          });
        } else {
          disableBodyScroll(this.$refs.disable);
          this.$store.commit('SET_TRANSITION_STATUS', true);

          gsap.set(el, {
            position: 'fixed',
            top: 0,
            autoAlpha: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 200,
          });

          setTimeout(() => {
            const video = document.querySelector('main.page .video--active');
            const videoPlayer = document.querySelector('main.page .video--active .video-player');
            const videoPlyr = videoPlayer.querySelector('.plyr');
            const videoPlyrVideo = videoPlayer.querySelector('.plyr video');

            const {
              top, left, width, height,
            } = video.getBoundingClientRect();
            const mask = document.querySelector('main.page .video--active .video__mask');

            const inset = `inset(${top}px ${width + left}px ${top + height}px ${left}px)`;
            gsap.set(mask, {
              clipPath: inset,
              webkitClipPath: inset,
            });

            const tl = gsap.timeline();

            tl.to(mask, {
              clipPath: 'inset(0px 0px 0px 0px)',
              webkitClipPath: 'inset(0px 0px 0px 0px)',
              ease: 'power2.inOut',
              duration: 1.2,
            });

            tl.to([videoPlayer, videoPlyr, videoPlyrVideo], {
              height: '100vh',
              ease: 'power2.inOut',
              duration: 1.2,
            }, 0);

            // const scale = Math.ceil(window.innerWidth / width);
            const y = Math.floor(top * -1);
            const x = Math.floor(left * -1);

            tl.to(video, {
              position: 'fixed',
              width: 'calc(100vw + 1px)',
              height: 'calc(100vh + 1px)',
              zIndex: 150,
              x,
              y,
              transformOrigin: '0% 0%',
              ease: 'power2.inOut',
              duration: 1.2,
            }, 0);

            tl.then(() => {
              const header = document.querySelector('header');
              gsap.to(header, {
                autoAlpha: 0,
                duration: 0.2,
              });

              video.classList.add('video--cloned');
              videoPlayer.classList.add('full-height');
              videoPlayer.style.removeProperty('padding-bottom');

              gsap.to(el, {
                autoAlpha: 1,
                duration: 0.5,
                ease: 'power2.out',
              });

              this.$store.commit('SET_TRANSITION_STATUS', false);
              clearAllBodyScrollLocks();

              const tease = document.querySelector('main.page .tease--active');
              gsap.set(tease.querySelector('.tease__spacer'), {
                paddingBottom: 140,
              });

              tease.querySelector('.accordion__content').classList.add('no-trans');

              done();
            });
          }, 500);
        }
      }

      if (this.transition === 'work-to-work') {
        const dir = this.isMobile ? 1 : this.$route.params.direction === 'next' ? 1 : -1;
        this.$store.commit('SET_TRANSITION_STATUS', true);
        done();
        gsap.set(el, {
          xPercent: 100 * dir,
        });

        gsap.to(el, {
          xPercent: 0,
          ease: 'power2.inOut',
          duration: 1.2,
          delay: 0.2,
        });
      }
    },
    leaveOverlay(el, done) {
      if (this.transition === 'from-about') {
        gsap.set('main.page', {
          clearProps: 'all',
        });

        const tl = gsap.timeline();

        tl
          .to(el.children[0], {
            clipPath: 'circle(0% at 100% 0%)',
            webkitClipPath: 'circle(0% at 100% 0%)',
            duration: 0.8,
            ease: 'power2.inOut',
          }, 0);

        tl.then(() => {
          this.$store.commit('SET_TRANSITION_STATUS', false);
          done();
        });
      } else if (this.transition === 'work-to-work') {
        const dir = this.isMobile ? -1 : this.$route.params.direction === 'next' ? -1 : 1;

        gsap.set(el, {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 500,
        });

        gsap.to(el, {
          xPercent: 100 * dir,
          ease: 'power2.inOut',
          duration: 1.2,
          delay: 0.2,
          onComplete: () => {
            this.$store.commit('SET_TRANSITION_STATUS', false);
            done();
          },
        });
      } else if (this.transition === 'from-work') {
        const video = document.querySelector('main.page .video--active');
        const tease = document.querySelector('main.page .tease--active');
        if (video) {
          this.$store.commit('SET_TRANSITION_STATUS', true);
          const mask = document.querySelector('main.page .video--active .video__mask');
          const videoPlayer = document.querySelector('main.page .video--active .video-player');

          gsap.to(video, {
            opacity: 0,
            duration: 1,
            onComplete: () => {
              clearAllBodyScrollLocks();

              const header = document.querySelector('header');
              gsap.to(header, {
                autoAlpha: 1,
                duration: 0.2,
              });

              video.classList.remove('video--cloned');
              this.$store.commit('SET_TRANSITION_STATUS', false);
              // done();

              if (videoPlayer) {
                videoPlayer.querySelector('.plyr__video-wrapper').classList.remove('blur');
                const videoPlyr = videoPlayer.querySelector('.plyr');
                const videoPlyrVideo = videoPlayer.querySelector('.plyr video');

                videoPlayer.classList.remove('full-height');

                const { ratio } = videoPlayer.dataset;

                gsap.set(video, {
                  position: 'relative',
                });

                gsap.set(video, {
                  width: 'auto',
                  height: 'auto',
                  x: 0,
                  y: 0,
                  clearProps: 'all',
                  transformOrigin: '0% 0%',
                });

                gsap.set([videoPlyr, videoPlyrVideo], {
                  height: '100%',
                });
                gsap.set(videoPlayer, {
                  height: 0,
                  paddingBottom: `${ratio}%`,
                });

                gsap.set(mask, {
                  clearProps: 'all',
                });

                gsap.set('main.page', {
                  clearProps: 'all',
                });

                this.$nextTick(() => {
                  window.scroll(0, this.lastScroll);

                  tease.querySelector('.accordion__trigger').click();
                });
              }
            },
          });

          done();
        } else {
          this.$store.commit('SET_TRANSITION_STATUS', true);
          gsap.set('main.page', {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
          });

          gsap.to(el, {
            autoAlpha: 0,
            duration: 1,
            onComplete: () => {
              gsap.set('main.page', {
                clearProps: 'all',
              });
              this.$store.commit('SET_TRANSITION_STATUS', false);

              const header = document.querySelector('header');
              gsap.to(header, {
                autoAlpha: 1,
                duration: 0.2,
              });

              done();
            },
          });
        }
      } else {
        gsap.set('main.page', {
          clearProps: 'all',
        });
        this.$store.commit('SET_TRANSITION_STATUS', false);
        done();
      }
    },
    closeAbout() {
      if (this.$route.name === 'About') {
        const prevPath = this.$store.state.route.from && this.$store.state.route.from.name ? this.$store.state.route.from.path : '/';
        this.$router.push(prevPath);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

</style>
