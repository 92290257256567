export default {
  ADD_ITEM(state, { type, item }) {
    // eslint-disable-next-line no-prototype-builtins
    if (item && type && !state.wp[type].hasOwnProperty(item.id)) {
      state.wp[type][item.slug] = item;
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state.wp[type].requests.push(request);
  },
  SET_LOADING(state, loading) {
    state.wp.site.loading = loading;
  },
  SET_DOC_TITLE(state, title) {
    state.site.docTitle = title;
  },
  SET_TOUCH(state, value) {
    state.touch = value;
  },
  SET_MOBILE(state, value) {
    state.mobile = value;
  },
  SET_BREAKPOINTS(state, value) {
    state.breakpoints = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_TRANSITION_STATUS(state, value) {
    state.transitionStatus = value;
  },
  REMOVE_LOADER(state) {
    state.loading = false;
  },
  SET_VOLUME(state, value) {
    state.volume = value;
  },
};
