import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import { setRequest } from '@/assets/js/utils';

import Page from '@/views/Page.vue';
import Single from '@/views/Single.vue';
// HINT: Adding a custom Post Type - 2
import SingleWork from '@/views/SingleWork.vue';
// import NotFound from '@/views/404.vue';

const singles = new Map();

singles.set('post', Single);
singles.set('work', SingleWork || Single);

Vue.use(VueRouter);

// eslint-disable-next-line no-undef
const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;
// eslint-disable-next-line no-undef
const { postTypes } = __VUE_WORDPRESS__.state;
// eslint-disable-next-line no-undef
const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

const routes = [
  {
    path: '/404',
    name: '404',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Index',
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: '/about',
    name: 'About',
    components: {
      default: Page,
      overlay: Page,
    },
    props: {
      default: { slug: page_on_front, type: 'pages' },
      overlay: { slug: 'about', type: 'pages' },
    },
  },
  {
    path: '/:slug',
    name: 'Page',
    component: Page,
  },
];

Object.keys(postTypes).forEach((key) => {
  if (key === 'work') {
    routes.push({
      path: `/${postTypes[key].rewrite.slug}/:slug`,
      name: singles.get(key).name,
      components: {
        default: Page,
        overlay: singles.get(key),
      },
      props: {
        default: { slug: 'works', type: 'pages' },
        overlay: { type: postTypes[key].rest_base },
      },
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  } else if (key !== 'page') {
    routes.push({
      path: `/${postTypes[key].rewrite.slug}/:slug`,
      name: singles.get(key).name,
      component: singles.get(key),
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior() {
    let scroll = { x: 0, y: 0 };
    if (store.state.transition === 'to-about' || store.state.transition === 'from-about') {
      scroll = false;
    }
    return scroll;
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === '404') {
    document.title = `404 — ${siteTitle}`;
    next();
    return;
  }
  document.body.classList.add('loading');

  if (to.name === 'Index') {
    document.body.classList.add('homepage');
  } else {
    document.body.classList.remove('homepage');
  }

  if (to.name === 'SingleWork') {
    document.body.classList.add('single');
  } else {
    document.body.classList.remove('single');
  }

  let transition = 'default';
  if (to.name === 'About') {
    transition = 'to-about';
  } else if (from.name === 'About') {
    transition = 'from-about';
  } else if (to.params.slug === 'works' && from.name === 'Index') {
    transition = 'to-catalog';
  } else if (to.name === 'SingleWork' && from.params.slug === 'works') {
    transition = 'to-work';
  } else if (from.name === 'SingleWork' && to.params.slug === 'works') {
    transition = 'from-work';
  } else if (to.name === 'SingleWork' && from.name === 'SingleWork') {
    transition = 'work-to-work';
  } else if (to.name === 'SingleWork' && from.name === 'Index') {
    transition = 'index-to-work';
  }
  store.commit('SET_TRANSITION', transition);

  const promises = [];

  const slug = to.name === 'About' ? 'about' : to.meta.slug ? to.meta.slug : to.params.slug;

  const request = {
    type: to.meta.type || 'pages',
    slug,
  };

  promises.push(store.dispatch('getSingleBySlug', request));

  if (to.name === 'About') {
    promises.push(store.dispatch('getSingleBySlug', {
      slug: page_on_front,
      type: 'pages',
    }));
  }

  if (to.name === 'SingleWork') {
    promises.push(store.dispatch('getSingleBySlug', {
      slug: 'works',
      type: 'pages',
    }));
  }

  Promise.all(promises).then((pages) => {
    if (pages && pages[0] && slug) {
      if (to.path === '/') {
        document.title = `${siteTitle} — ${description}`;
      } else {
        document.title = `${pages[0].title.rendered} — ${siteTitle}`;
      }

      const page = pages.find((p) => p.slug === 'index');

      if (page && typeof page !== 'undefined' && page.acf && page.acf.sections) {
        const promisesArchives = [];
        page.acf.sections.forEach((section) => {
          const archives = section.blocks.filter((block) => block.acf_fc_layout === 'archive');
          archives.forEach((archive) => {
            const archiveRequest = setRequest(archive);
            promisesArchives.push(store.dispatch('getItems', archiveRequest));
          });
        });

        Promise.all(promisesArchives).then(() => {
          document.body.classList.remove('loading');
          next();
        });
      } else {
        document.body.classList.remove('loading');
        next();
      }
    } else {
      router.push({ name: '404' });
    }
  });
});

export default router;
