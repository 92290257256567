<template>
  <div
    :class="['video-player', fh ? 'full-height' : null]"
    :style="!fh ? { paddingBottom: `${(data.height / data.width) * 100}%` } : null"
    :data-ratio="(data.height / data.width) * 100"
  >
    <video
      v-if="!preview"
      ref="player"
      class="video plyr"
      playsinline
      preload="none"
    />
    <Figure
      v-else
      :data="{ url: data.pictures.sizes[5].link }"
      class="poster"
      :lazy="false"
    />
  </div>
</template>

<script>
import orderBy from 'lodash.orderby';
import { mapGetters } from 'vuex';

import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Figure from '@/components/media/figure';

export default {
  name: 'VideoPlayer',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fh: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plyr: null,
      playing: false,
      loading: false,
      setted: false,
    };
  },
  computed: {
    ...mapGetters(['volume', 'transitionStatus']),
  },
  watch: {
    transitionStatus(val) {
      if (!val && !this.setted) {
        this.setted = true;
        this.plyr = new Plyr(this.$refs.player, {
          controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume'],
          autoplay: false,
          loop: { active: this.loop },
          muted: this.muted ? this.muted : !this.volume,
          hideControls: false,
          storage: { enabled: false },
          clickToPlay: false,
          fullscreen: { enabled: false },
          keyboard: {
            focused: false,
            global: false,
          },
        });

        if (this.data.files) this.plyr.source = this.setSource();
      }
    },
  },
  mounted() {
    if (!this.preview && !this.transitionStatus) {
      this.setted = true;
      this.plyr = new Plyr(this.$refs.player, {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume'],
        autoplay: false,
        muted: this.muted ? this.muted : !this.volume,
        hideControls: false,
        loop: { active: this.loop },
        storage: { enabled: false },
        clickToPlay: false,
        fullscreen: { enabled: false },
        keyboard: {
          focused: false,
          global: false,
        },
      });

      if (this.data.files) this.plyr.source = this.setSource();
    }
  },
  methods: {
    setSource() {
      const source = {
        type: 'video',
        sources: [],
        poster: this.data.pictures ? this.data.pictures.sizes[5].link : null,
      };

      if (!this.data.files) {
        return null;
      }

      const width = window.innerWidth <= 960 ? 960 : window.innerWidth;

      const dateFiles = orderBy(this.data.files, ['width'], ['asc']);
      const files = dateFiles.filter((file) => (file.width <= width));
      const file = files.length > 0 ? files[files.length - 1] : dateFiles[0];

      source.sources.push({
        src: file.link,
        type: file.type,
        size: file.width,
      });

      return source;
    },
  },
};
</script>

<style lang="scss" scoped>
.poster {
  height: 100%;

  ::v-deep picture {
    height: 100%;
  }
}
</style>

<style lang="scss">
.video-player:not(.full-height) {
  position: relative;
  width: 100%;
  height: 0;
}

.video-player.full-height {
  height: calc(var(--vh, 1vh) * 100);

  .plyr {
    height: calc(var(--vh, 1vh) * 100);
  }
  .plyr video {
    object-fit: contain;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);

    /* @include mq(m){
      object-fit: cover;
    } */
  }
}
.video-player:not(.full-height) .plyr {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}
</style>
