<template>
  <footer
    v-if="$route.name === 'Page'"
    class="page-footer"
  >
    <div
      v-if="options && options.description "
      class="page-footer__description"
      v-html="options.description"
    />
    <div
      v-if="options && options.list"
      class="page-footer__list"
    >
      <Flex
        v-for="(item, index) in options.list"
        :key="index"
        align="h-between"
      >
        <div v-html="item.label" />
        <div v-html="item.content" />
      </Flex>
    </div>
    <div
      v-if="options && options.social"
      class="page-footer__social"
    >
      <div
        v-for="(item, index) in options.social"
        :key="index"
      >
        <a
          :href="item.url"
          v-html="item.label"
        />
      </div>
    </div>
    <div
      v-if="options && options.credits"
      class="page-footer__credits"
    >
      <Flex
        v-for="(item, index) in options.credits"
        :key="index"
        align="h-between"
      >
        <div v-html="item.label" />
        <div v-html="item.content" />
      </Flex>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    options() {
      const { footer } = this.$store.state.wp.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
